$mobileBreakPoint: 400px;
$padding: 16px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == "small" {
    @media (min-width: 767px) {
      @content;
    }
  }

  @else if $breakpoint == "medium" {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $breakpoint == "large" {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

.Leaderboard
{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: $padding;

  // @include respond-to(medium)
  // {
  //   padding: 64px;
  // }


  &-missedCut
  {
    background-color: #cf4408;
  }

  &-title
  {
    margin-bottom: 32px;
  }

  &-table
  {
    display: block;
  }

  &-th
  {
    font-size: 24px;
  }

  td,
  th
  {
    border: 2px solid white;
    padding: 8px
  }

  tr
  {
    padding: 8px;
  }

  &-cutData
  {
    padding: 4px 6px !important;
  }
}

.Update
{
  font-style: italic;
  margin-bottom: 8px;
}